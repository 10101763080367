import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Periodontal2Page: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const product1 = data.product1?.childImageSharp?.fluid
  const father_and_daughter_brushing_teeth_in_bathroom = data.father_and_daughter_brushing_teeth_in_bathroom?.childImageSharp?.fluid
  const close_up_of_woman_using_dental_floss_from_pack_in_bathroom =data.close_up_of_woman_using_dental_floss_from_pack_in_bathroom?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Meta site={meta} title="Periodontal Treatment" />
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
              PERIODONTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
              Periodontal Treatment
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer periodontal treatments to help you achieve healthier gums and the perfect smile. Browse the page below to learn more. It’s easy to schedule your appointment with us. </p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <button className="btn-rounded"> Learn More </button>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">PERIODONTAL PROCEDURES</p>
              <h2 className ="section-2-header">Periodontal Treatments Keep Gums Healthy</h2>
              <p>Teeth are usually the first thing you think about when you think about oral health. The gums, however, are just as important and keeping them healthy is paramount to keeping your teeth. This is where periodontal treatments come in. You don’t always need to go see a periodontal specialist to treat your gums and rid them of disease. Dr. Vogt is a very capable dentist and makes periodontal procedures available to his patients.</p>
            </div>
          </Container>
          <Container>
          <div className="col-lg-6">
              <div className="col-md-6">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Better Pay & Benefits</h3>
                <p>People who have healthy smiles tend to get better pay and benefits. Make sure you take good care of your gums to keep your teeth.</p>
              </div>
              <div className="col-md-6">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Great First Impressions</h3>
                <p>Your smile is like a beacon. A healthy set of teeth will send out good signals. A bad set of teeth sends a different message. Taking care of your gums by brushing and flossing will keep your teeth healthy longer.</p>
              </div>
                <div className="col-md-6">
                  <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                  <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                  <p>People can tell when you have something that is keeping you from feeling confident. Make sure you're taking good care of your gums so you don't have to worry about your teeth.</p>
                </div>
            </div>
            <div className="col-lg-6">
              <Img fluid = {father_and_daughter_brushing_teeth_in_bathroom} />
            </div>
          </Container>
          <Container>
            <div className =" col-lg-12"><p></p></div>
          </Container>
          <Container>
          <div className="col-lg-6">
            <Img fluid = {close_up_of_woman_using_dental_floss_from_pack_in_bathroom} />
          </div>
          <div className = "col-lg-6">
            <div>
              <i className="fa fa-bolt" aria-hidden="true"></i>
              <h3 className ="section-1-header">Improves Overall Dental Health</h3>
              <p>Healthy gums usually means healthy teeth. Invest the time in to taking care of your gums and the rest of your mouth will benefit too.</p>
            </div>
            <div>
              <i className="fa fa-diamond" aria-hidden="true"></i>
              <h3 className ="section-1-header">Smile More & Live Longer</h3>
              <p>People who live longer tend to smile more. You can live a happier longer life too. Have your dentist check your gums regularly.</p>
            </div>
            <div>
              <i className="fa fa-bookmark-o" aria-hidden="true"></i>
              <h3 className ="section-1-header">High Trust & Productivity</h3>
              <p>Smiling gives you energy. It also gives people the impression that they can trust you. Combine both of these things and suddenly you're able to go further in life.</p>
            </div>
          </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="the dentists at crossroad"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                LOVE YOUR SMILE!
                </p>
                <h3 className="text-light">
                Don't miss your chance to live life with the smile you've always wanted!
                </h3>
                <p className="hero-text text-light">
                Stop delaying your life. You’re missing out on so many opportunities by being embarrassed about your teeth! Call TODAY!
                </p>
                <a href="tel:3176439434" className="text-left"><button className="btn-rounded"><i className="fa fa-phone"></i> (317) 643-9434 </button></a>
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">PERIODONTAL DETAILS</p>
              <h2 className ="section-3-header">What Are Periodontal Treatments ?</h2>
             <p>Periodontal treatments are procedures performed by a dental hygienist or dentist specializing in periodontics to help patients that have been diagnosed with periodontitis.</p>
             <p>Root planing and scaling, one way of treating periodontitis, is a procedure that involves removing dental plaque and calculus (tartar) above and below the gum line in patients suffering from this sever form of gum disease.</p>
             <p>This procedure is often referred to as a deep cleaning as it is more invasive than a standard teeth cleaning. </p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Periodontal Treatments Right For You?</h3>
                <p>You may been told that you have gum disease and you’re wondering if you need periodontal treatment. Many times what was once a mild case of gingivitis is left untreated and turns into the more sever form of periodontitis.</p>
                <p>When this happens, the gums become inflamed and pockets form on either side of the teeth. These pockets cause food and bacteria to become trapped in parts of your gums that brushing and flossing cannot reach. The bones in the surrounding areas become inflamed and your teeth start to loosen.</p>
                <p>This is when you need periodontal treatments. After you have had your deep clean (root planing and scaling) you will need perio maintenance in order to keep the periodontitis at bay. Unfortunately periodontitis is not completely curable and it will require you to keep up with it for the. rest of your life. This is why the dentist tells you that is so important to take preventative measure like brushing and flossing very seriously.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Periodontal Treatments</h3>
                <p>Dr. Vogt has made the following treatments available to his patients suffering from periodontitis:</p>
                <ul>
                  <li>Scaling and Root Planing</li>
                  <li>Gum Grafting</li>
                  <li>Flap Surgery</li>
                  <li>Bone Grafting</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Periodontal Treatments Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <a href="tel:3176439434">317-643-9434</a>.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default Periodontal2Page

export const query = graphql`
  query Periodontal2PageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    father_and_daughter_brushing_teeth_in_bathroom: file(name: { eq: "little-boy-with-open-mouth-in-a-dental-cabinet" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    close_up_of_woman_using_dental_floss_from_pack_in_bathroom: file(name: { eq: "dental-exam" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
